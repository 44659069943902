var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{attrs:{"id":"top-bar"}},[_c('b-container',{staticClass:"header-container desktop"},[_c('div',{attrs:{"id":"menu"}},[_c('div',{staticClass:"nav-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":""}})]),_c('div',{staticClass:"nav-menu"},[_c('a',{staticClass:"menu-item",attrs:{"href":"https://marketplace.herobook.io/","target":"_blank"}},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("@/assets/images/icons/market.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("marketplace")])]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: 'Blog', params: { locale: _vm.Language } }}},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("@/assets/images/icons/shop.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("Blog")])]),_c('a',{staticClass:"menu-item",attrs:{"href":"https://whitepaper.herobook.io/","target":"_blank"}},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("@/assets/images/icons/dashboard.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("document")])]),_c('div',{staticClass:"lang-menu"},[_c('b-nav-item-dropdown',{staticClass:"animated animate__zoomIn launch-app menu-lang",attrs:{"left":"","no-caret":"","menu-class":"custom-dropdown"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{attrs:{"src":_vm.languageActive.flag,"alt":"flag_active","width":"100%","height":"100%"}})]},proxy:true}])},_vm._l((_vm.languages),function(lang,i){return _c('b-dropdown-item',{key:("Lang" + i),class:{
                                active:
                                    _vm.languageActive.language ===
                                    lang.language,
                            },attrs:{"value":lang},on:{"click":function($event){return _vm.setLanguage(lang.language, lang.flag)}}},[_c('img',{attrs:{"src":("" + (lang.flag)),"alt":"","width":"100%","height":"100%"}})])}),1)],1)],1)])]),_c('b-container',{staticClass:"header-container mobile"},[_c('div',{attrs:{"id":"nav-mobile"}},[_c('div',{staticClass:"nav-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":""}})]),_c('div',{staticClass:"toggle-menu-mobile"},[_c('b-button',{on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[(!_vm.showMenu)?_c('img',{attrs:{"src":require("@/assets/images/icons/menu.png"),"alt":""}}):_vm._e(),(_vm.showMenu)?_c('img',{attrs:{"src":require("@/assets/images/icons/close.png"),"alt":""}}):_vm._e()])],1),(_vm.showMenu)?_c('div',{staticClass:"sidebar-menu"},[_c('div',{staticClass:"nav-menu"},[_c('a',{staticClass:"menu-item",attrs:{"href":"https://whitepaper.herobook.io/","target":"_blank"}},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("@/assets/images/icons/dashboard.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("document")])]),_c('a',{staticClass:"menu-item",attrs:{"href":"https://marketplace.herobook.io/","target":"_blank"}},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("@/assets/images/icons/market.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("marketplace")])]),_c('router-link',{staticClass:"menu-item",attrs:{"to":{
                            name: 'Blog',
                            params: { locale: _vm.Language },
                        }}},[_c('div',{staticClass:"icons"},[_c('img',{attrs:{"src":require("@/assets/images/icons/shop.png"),"alt":""}})]),_c('div',{staticClass:"text"},[_vm._v("Blog")])]),_c('div',{staticClass:"lang-menu-mb"},[_c('b-nav-item-dropdown',{staticClass:"animated animate__zoomIn launch-app menu-lang",attrs:{"left":"","no-caret":"","menu-class":"custom-dropdown"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{attrs:{"src":_vm.languageActive.flag,"alt":"flag_active","width":"100%","height":"100%"}})]},proxy:true}],null,false,411493282)},_vm._l((_vm.languages),function(lang,i){return _c('b-dropdown-item',{key:("Lang" + i),class:{
                                    active:
                                        _vm.languageActive.language ===
                                        lang.language,
                                },attrs:{"value":lang},on:{"click":function($event){return _vm.setLanguage(lang.language, lang.flag)}}},[_c('img',{attrs:{"src":("" + (lang.flag)),"alt":"","width":"100%","height":"100%"}})])}),1)],1)],1)]):_vm._e(),(_vm.showMenu)?_c('div',{staticClass:"back-drop",on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}}):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }