import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import i18n from '../i18n';

const langSupport = ['vi', 'en'];

let first = true;

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});
// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
    const { type, id } = to.params;
    const { name } = to;
    const { locale } = to.params; // 1

    if (!langSupport.includes(locale)) return next('en'); // 3
    if (i18n.locale !== locale) {
        i18n.locale = locale;
    }

    if (type && first && !id) {
        if (
            type.slice(0, 3).toLocaleLowerCase() !==
            name.slice(0, 3).toLocaleLowerCase()
        ) {
            next('/all-articles');
            first = false;
        }
        next();
    } else {
        next();
    }
});

export default router;
