<template>
    <div class="HeroBook">
        <Header />
        <loader />
        <main class="content">
            <div class="sub-header">
                <div
                    class="sub-item"
                    v-for="sub in BlogType"
                    :key="`sub-menu-${sub.blog_id}`"
                >
                    <router-link
                        :to="{
                            name: sub.components,
                        }"
                    >
                        {{ sub.blog_name }}
                    </router-link>
                </div>
                <b-container class="drop-container">
                    <transition name="slide">
                        <b-dropdown
                            id="dropdown-left"
                            :text="getTabActive"
                            variant="outline-dark"
                            class="m-2"
                        >
                            <b-dropdown-item
                                :to="{
                                    name: sub.components,
                                    params: { locale: Language },
                                }"
                                v-for="sub in BlogType"
                                :key="`drop-menu-${sub.blog_id}`"
                            >
                                {{ sub.blog_name }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </transition>
                </b-container>
            </div>

            <router-view></router-view>
        </main>
        <Footer />
    </div>
</template>

<script>
import { Header, Footer } from '@/components/Layouts';
import { mapMutations, mapGetters } from 'vuex';
import loader from '@/components/shared/Loader.vue';
import filter from 'lodash/filter';

export default {
    components: { Header, loader, Footer },
    data() {
        return {
            tabActive: '',
            propertiesActive: '',
        };
    },
    computed: {
        ...mapGetters({
            BlogType: 'blog/BlogType',
            Language: 'Language',
        }),
        getTabActive() {
            if (this.BlogType.length > 0) {
                const tabActive = filter(this.BlogType, (el) => {
                    return (
                        el.components.toLowerCase() ===
                            this.propertiesActive.toLowerCase() ||
                        el.blog_slug.toLowerCase() ===
                            this.propertiesActive.toLowerCase()
                    );
                });
                return tabActive.length > 0 ? tabActive[0].blog_name : null;
            }
            return null;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
    },
    mounted() {
        const { type } = this.$route.params;
        if (type) {
            this.propertiesActive = type;
        } else {
            this.propertiesActive = this.$route.name;
        }
    },
    watch: {
        $route(to) {
            const { type } = to.params;
            if (type) {
                this.propertiesActive = type;
            } else {
                this.propertiesActive = this.$route.name;
            }
        },
        Language() {
            this.$store.dispatch({ type: 'blog/req_getBlogType' });
        },
    },
};
</script>

<style lang="scss">
.drop-container {
    display: flex;
    @media (min-width: 992px) {
        display: none;
    }
}
.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
}
#dropdown-left {
    display: none;
    button {
        min-width: 150px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent !important;
        color: #001628 !important;
        font-size: 1.2em;
        box-shadow: none !important;
    }
    @media (max-width: 991px) {
        display: block;
    }
    .dropdown-menu {
        min-width: 150px;
        width: auto;
        outline: none !important;
        transition: transform 0.4s ease-in-out;
        .dropdown-item {
            font-weight: 500;
            font-size: 1.1em;
            &:hover,
            &.active {
                background-color: transparent !important;
                color: #001628;
                font-weight: 600;
            }
        }
    }
    .btn.dropdown-toggle {
        border: 0;
        font-weight: 600;
        text-transform: capitalize;
    }
}
main.content {
    min-height: calc(100vh - 90px);
    height: 100%;
    padding-top: 15px;
    background: {
        color: #ece8e142;
        size: cover;
        position: bottom center;
        repeat: no-repeat;
    }
    .sub-header {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid rgba(204, 204, 204, 0.329);
        .sub-item {
            margin-right: 35px;
            a {
                display: block;
                height: 100%;
                padding: 25px 5px;
                color: #383e3a;
                font-size: 1.2em;
                font-weight: 500;
                text-decoration: none;
                &.active {
                    border-bottom: 3px solid #001628;
                    font-weight: 600;
                    color: #001628;
                    padding-bottom: 25px;
                }
            }
        }
        @media (max-width: 991px) {
            justify-content: flex-start;
            .sub-item {
                display: none;
            }
        }
    }
}
</style>
