import Vue from 'vue';
import Vuex from 'vuex';

import core from './modules/core';
import blog from './modules/blog';

Vue.use(Vuex);

export default new Vuex.Store({
    state: () => ({
        loadingStatus: false,
        totalSend: 0,
        totalReceive: 0,
        error: '',
        success: '',
        status: 0,
        language: window.$cookies.get('language') || 'en',
    }),
    getters: {
        LoadingStatus: (state) => state.loadingStatus,
        Language: (state) => state.language,
    },
    mutations: {
        onLoad(state) {
            this.commit('StatusLoad', true);
            state.totalSend += 1;
        },
        outLoad(state) {
            state.totalReceive += 1;
            if (state.totalSend <= state.totalReceive) {
                this.commit('StatusLoad', false);
            }
        },
        StatusLoad: (state, data) => {
            if (data) {
                state.loadingStatus = true;
                return;
            }
            state.loadingStatus = false;
            state.totalSend = 0;
            state.totalReceive = 0;
        },
        SET_LANGUAGE(state, data) {
            state.language = data;
        },
    },
    actions: {
        changeLanguage: ({ commit }, data) => {
            commit('SET_LANGUAGE', data);
        },
    },
    modules: {
        core,
        blog,
    },
});
